<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Nota de Crédito y Débito - Agregar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form @submit.prevent="Validate">
              <b-row>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label ">Comprobante:   </label>
                    <b-form-select @change="GetSeries" v-model="payment.voucher_type" :options="voucher_type"></b-form-select>
                    <small v-if="errors.voucher_type" class="form-text text-danger" >Seleccione un comprobante</small>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Serie: </label>
                    <b-form-select @change="GetNumberBySerie" v-model="payment.id_serie" :options="series"></b-form-select>
                    <small v-if="errors.id_serie" class="form-text text-danger" >Seleccione una serie</small>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Tipo Operación: </label>
                    <b-form-select v-model="payment.operation_type" :options="operation_type"></b-form-select>
                    <small v-if="errors.operation_type" class="form-text text-danger" >Seleccione una tipo</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Fecha Emision: </label>
                    <b-form-input class="text-center" readonly v-model="payment.broadcast_date" type="text"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: <span v-if="bnt_fees_collected" class="badge badge-primary link" @click="ModalFeedCollected">Cuotas</span></label>
                    <b-form-select @change="BntFeesCollected" v-model="payment.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione ua forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col sm="3" md="2">
                  <b-form-group>
                    <label class="control-label">Moneda: </label>
                    <b-form-select disabled v-model="payment.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                  </b-form-group>
                </b-col>

                 <b-col sm="12" md="9">
                  <b-form-group>
                    <label class="control-label">Cliente:</label>
                    <b-form-input disabled v-model="payment.client"></b-form-input>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Motivo :">
                     <b-form-select v-model="payment.modified_reason" :options="reason"></b-form-select>
                    <small v-if="errors.reason" class="form-text text-danger">Ingrese un motivo</small>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  
                    <div class="table-responsive  mt-3">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th width="5%" class="text-center">#</th>
                            <th width="10%" class="text-center">Codigo</th>
                            <th width="49%" class="text-center">Nombre</th>
                            <th width="7%" class="text-center">Cantidad</th>
                            <th width="10%" class="text-center">P. Unit</th>
                            <th width="10%" class="text-center">P. Total</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in payment.payment_detail" :key="index">
                          <tr>
                              <td class="text-center">{{ index + 1 }}</td>
                              <td class="text-left">{{item.code}}</td>
                              <td class="text-left">{{item.name}}</td>
                              <td class="text-center">{{item.quantity}}</td>
                              <td class="text-right">{{item.unit_price}}</td>
                              <td class="text-right">{{item.total_price}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <small v-if="errors.total" class="form-text text-center text-danger" >Ingrese un monto</small>
                </b-col>
                <b-col class="mt-4" md="12"></b-col>
                <b-col sm="12" md="8">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-input readonly v-model="payment.number_to_letters" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <div class="table-responsive mt-1">
                          <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                            <thead>
                              <tr>
                                <th width="25%" class="text-center">Fecha</th>
                                <th width="75%" class="text-center">Referencia</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, it) in payment.linkages" :key="it">
                              <tr>
                                  <td class="align-middle text-center">{{ item.broadcast_date }}</td>
                                  <td class="align-middle text-center">{{ item.reference }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Observación:">
                        <b-form-textarea rows="1"  v-model="payment.observation" max-rows="2"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  
                </b-col>
                <b-col sm="12" md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Subtotal :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.subtotal" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  :label="'Igv ('+ payment.igv_percentage +'%):'" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.igv" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Total :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.total" readonly ></b-form-input>
                  </b-form-group>

                </b-col>

  
                <b-col md="5"></b-col>
          
                <b-col md="2">
                    <b-button type="submit" variant="primary" class="btn form-control">Guardar</b-button>
                </b-col>

         
              </b-row>
            </b-form>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

 

  </div>
</template>

<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import converter from "@/assets/js/NumberToLetters";
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "ModalRoomControl",
  props: ["id_payment"],
  components:{
    vSelect,
    LoadingComponent
  },
  data() {
    return {
      isLoading: false,
        fullPage: true,
        module: 'RoomControl',
        role:3,
        modal_payment:false,
        modal_fees_collected:false,
        voucher_type:[
          {value:'07', text: 'NOTA DE CRÉDITO'},
        ],

        serie_sale:'',
        payment: {
          client:'',
          way_to_pay:'01-008',
          operation_type: '01',
          id_payment:'',
          id_room_control:'',
          id_client:'',
          id_user:'',
          id_serie:'',
          voucher_type:'07',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          broadcast_time:moment(new Date()).local().format("HH-mm-ss"),
          expiration_date:moment(new Date()).local().format("YYYY-MM-DD"),
          coin:'PEN',
          payment_type:'',
          payment_method:'',
          payment_deadline:'',
          observation:'',
          modified_voucher_type:'',
          modified_serie:'',
          modified_number:'',
          modified_broadcast_date:'',
          modified_reason:'01',
          modified_support:'',
          sunat_message:'',
          hash_cpe:'',
          hash_cdr:'',
          taxed_operation: (0).toFixed(2),
          exonerated_operation:(0).toFixed(2),
          unaffected_operation:(0).toFixed(2),
          export_operation:(0).toFixed(2),
          
          discount:(0).toFixed(2),
          subtotal:(0).toFixed(2),
          igv:(0).toFixed(2),
          total:(0).toFixed(2),
          cash_payment:(0).toFixed(2),
          card_payment:(0).toFixed(2),
          web_way_payment:(0).toFixed(2),
          fees_collected:[],
          state:'1',
          number_to_letters : '',
          payment_detail:[],
          linkages:[],
          address:'',
          igv_percentage:0,

        },
        
        reason:[
          {value:'01',text:'Anulación de la operación'},
          {value:'02',text:'Anulación por error en el ruc'},
          {value:'06',text:'Devolución total'},
          // {value:'07',text:'Devolucion por item'},
        ],
        room_control: {
          id_room_control:0
        },
        fees_collected: [],
        fee_collected: {
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          total: (0).toFixed(2),
          min_date:moment(new Date()).local().format("YYYY-MM-DD"),
          max_date:moment(new Date()).local().format("YYYY-MM-DD"),
        },
        payments:[],


        room_control_detail:[],

        series:[],
        clients:[],
        client:null,
        bnt_fees_collected: false,
        way_to_pay:[
            {value:'01-008',text:'Contado - Efectivo'},
            {value:'01-001',text:'Contado - Depósito en Cuenta'},
            {value:'01-002',text:'Contado - Giro'},
            {value:'01-003',text:'Contado - Transferencia de Fondos'},
            {value:'01-004',text:'Contado - Orden de Pago'},
            {value:'01-005',text:'Contado - Tarjeta de Débito'},
            {value:'03-7',text:'Credito - 7 Dias'},
            {value:'03-15',text:'Credito - 15 Dias'},
            {value:'03-30',text:'Credito - 30 Dias'},
            {value:'03-45',text:'Credito - 45 Dias'},
            {value:'03-60',text:'Credito - 60 Dias'},
            {value:'03-75',text:'Credito - 75 Dias'},
            {value:'03-90',text:'Credito - 75 Dias'},
        ],
        coins:[
          {value: 'PEN', text:'PEN'},
          {value: 'USD', text:'USD'},
          {value: 'CLP', text:'CLP'},
        ],
        operation_type: [
          {value: '01', text:'Venta Interna'},
          {value: '02', text:'Exportación'},
        ],
        errors:{
          id_client:false,
          voucher_type:false,
          way_to_pay:false,
          total:false,
          id_serie:false,
        },
        validate: false,


    };
  },
  mounted() {
   this.GetSeries();
   this.ViewPayment();
    
  },
  methods: {
    CodeInvoice,
    GetSeries,
    GetNumberBySerie,
    Validate,
    BntFeesCollected,
    ViewPayment,
    DataPayment,Print,
    AddPayment,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function GetSeries() {
  let me = this;
  let url = this.url_base + "serie/series-by-voucher/"+this.payment.voucher_type;
  this.series = [];
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.id_serie = '';
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.series.push({value: element.id_serie, text: element.serie +'-'+element.number});
          me.payment.id_serie = element.id_serie;
          me.GetNumberBySerie();
        }

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}

function GetNumberBySerie() {
  let me = this;
  let url = this.url_base + "serie/view/"+this.payment.id_serie;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.serie = response.data.result.serie;
        me.payment.number = response.data.result.number;
      } else {
        me.payment.serie = '';
        me.payment.number = '';
      }
    })
}

function ViewPayment() {
  let id_payment = je.decrypt(this.id_payment);
  let me = this;
  let url = this.url_base + "payment/view/" + id_payment;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.igv_percentage = response.data.result.payment.igv_percentage;
        me.payment.payment_detail = response.data.result.payment_detail;
        me.payment.coin = response.data.result.payment.coin;
        me.payment.id_room_control = response.data.result.payment.id_room_control;
        me.payment.id_client = response.data.result.payment.id_client;
        me.payment.client = response.data.result.payment.name + ' - '+response.data.result.payment.document_number;
        me.payment.payment_detail = response.data.result.payment_detail;

        me.payment.operation_type = response.data.result.payment.operation_type;
        me.payment.taxed_operation = response.data.result.payment.taxed_operation;
        me.payment.exonerated_operation = response.data.result.payment.exonerated_operation;
        me.payment.unaffected_operation = response.data.result.payment.unaffected_operation;
        me.payment.export_operation = response.data.result.payment.export_operation;
        me.payment.discount = response.data.result.payment.discount;
        me.payment.subtotal = response.data.result.payment.subtotal;
        me.payment.igv = response.data.result.payment.igv;
        me.payment.total = response.data.result.payment.total;
        me.payment.number_to_letters = response.data.result.payment.number_to_letters;
        me.serie_sale = response.data.result.payment.serie;
        me.payment.linkages.push({
          id_module:response.data.result.payment.id_payment,
          module: 'NoteCredit',
          reference: me.CodeInvoice(response.data.result.payment.voucher_type) + " "+ response.data.result.payment.serie+"-"+response.data.result.payment.number,
          broadcast_date:response.data.result.payment.broadcast_date,
        });
      } 
    })
}

function Validate() {

  this.validate = false;

  this.errors.voucher_type = this.payment.voucher_type.length == 0 ? true : false;
  this.errors.id_serie = this.payment.id_serie.length == 0 ? true : false;
  this.errors.way_to_pay = this.payment.way_to_pay.length == 0 ? true : false;
  this.errors.id_client = this.payment.id_client == null ? true : false;
  this.errors.total = parseFloat(this.payment.total) == 0 ? true : false;

  let serie = this.serie_sale;
  let serie_sale = this.payment.serie;
  console.log(serie);
  console.log(serie_sale);

  if (serie.indexOf("F") !== -1){
    if (serie_sale.indexOf("F") !== -1){
    }else{
      this.validate = true; Swal.fire({ icon: 'warning', text: 'La serie seleccionada no coincide con el comprobante adjuntado', timer: 2000,}); return false;
    }
  }

  if (serie.indexOf("B") !== -1){
    if (serie_sale.indexOf("B") !== -1){
    }else{
      this.validate = true; Swal.fire({ icon: 'warning', text: 'La serie seleccionada no coincide con el comprobante adjuntado', timer: 2000,}); return false;
    }
  }

  if (this.errors.voucher_type) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.id_serie) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.total) { this.validate = true; return false;}else{ this.validate = false; }
  


  let me = this;

  Swal.fire({
    title: 'Esta seguro de guardar el comprobante ?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      AddPayment(me);
    }
  })

}

function AddPayment(me) {
  me.payment.id_user = me.user.id_user;
  me.payment.fees_collected = me.fees_collected;
  me.isLoading = true;
  let url = me.url_base + "payment/add";
  let data = me.payment;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module,role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.isLoading = false;
        Swal.fire("Sistema", "Se ha realizado el pago ", "success");
        me.DataPayment(response.data.result.id_payment);
        me.$router.push({
          name: "PaymentList",
        });
      } else if  (response.data.status == 400) {
        me.isLoading = false;
        Swal.fire("Sistema", response.data.message, "error");
      }else{
        me.isLoading = false;
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function DataPayment(id_payment) {

  let me = this;
  let url = this.url_base + "payment/data-print/"+id_payment;
  axios({
    method: "GET",
    url: url,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        let data = {
          business : response.data.business,
          payment : response.data.payment,
          payment_detail : response.data.payment_detail,
        }
        me.Print(data);
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}



function Print(payment) {

  // let me = this;
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("business",JSON.stringify(payment.business)); 
  data.append("payment",JSON.stringify(payment.payment));  
  data.append("payment_detail",JSON.stringify(payment.payment_detail));  
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}




// CUOTAS DE PAGO
function BntFeesCollected() {
  let payment_type = this.payment.way_to_pay.split('-');
  let days = 0;
  if (payment_type[0] == "03") {
    this.bnt_fees_collected = true;
    days = payment_type[1];
  }else{
    this.bnt_fees_collected = false;
  }
}

function ModalFeedCollected() {
  this.modal_fees_collected = true;
  let payment_type = this.payment.way_to_pay.split('-');
  let days = payment_type[1];
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  this.fee_collected.max_date = moment(this.fee_collected.min_date, "YYYY-MM-DD").add('days',parseInt(days)).local().format("YYYY-MM-DD");
  
}

function AddFeedCollected() {
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  balance = parseFloat(this.payment.total) - parseFloat(balance); 


  if (this.fee_collected.date.length == 0) {
    return false;
  }
  if (this.fee_collected.total.length == 0 || balance < parseFloat(this.fee_collected.total))  {
    return false;
  }
  
  this.fees_collected.push({date:this.fee_collected.date, total: parseFloat(this.fee_collected.total).toFixed(2)});

  balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  
}

function DeleteFeedCollected(index) {
  for (var i = 0; i < this.fees_collected.length; i++) {
    if (i == index) {
      this.fees_collected.splice(i, 1); break;
    }
  }

  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);
  
}


</script>
